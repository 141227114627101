import React, { AnchorHTMLAttributes, useState } from 'react';

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;

const Anchor: React.FC<AnchorProps> = ({ children, ...rest }) => {
  const [url] = useState(' ');
  return (
    <a href={url} className=".anchor" {...rest}>
      {children}
    </a>
  );
};

export default Anchor;
