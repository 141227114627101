import { ReactElement, useState, useRef } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import logo from '../../assets/imagens/whatsapi-logo.png';
import Footer from '../../components/Footer';
import { useAutenticacao } from '../../context/Usuario';
import getValidarErros from '../../utils/getValidarErros';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Anchor from '../../components/Anchor';

interface DadosFormulario {
  email: string;
  password: string;
}

const Login = (): ReactElement => {
  const [verSenha, setVerSenha] = useState<boolean>(false);
  const formRef = useRef<FormHandles>(null);
  const { login } = useAutenticacao();

  const handleLogin = async (data: DadosFormulario) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string()
          .required('E-mail obrigatório')
          .email('Digite um e-mail válido'),
        password: Yup.string().min(6, 'No mínimo 6 digitos'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
    } catch (error) {
      const erros = getValidarErros(error);
      formRef.current?.setErrors(erros);
    }

    await login({
      email: data.email,
      password: data.password,
    });
  };

  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <div className="nk-wrap nk-wrap-nosidebar">
          <div className="nk-content">
            <div style={{ flex: 1 }} className="align-center justify-center">
              <div className="fadeInUp fadeOutDown animated nk-block nk-block-middle nk-auth-body wide-xs">
                <div className="brand-logo pb-4 text-center">
                  <h1 className="nk-error-head">
                    <img src={logo} alt="logo" style={{ width: 400 }} />
                  </h1>
                </div>
                <div className="card card-bordered">
                  <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <div className="d-flex justify-center mb-4">
                          <h4 className="nk-block-title">Login</h4>
                        </div>
                        <div className="nk-block-des text-center">
                          <p>
                            Acesse os serviços de integração ao whatsapp
                            informando o email que você cadastrou nos produtos
                            Maxscalla.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Form ref={formRef} onSubmit={handleLogin}>
                        <div className="form-group">
                          <div className="form-label-group">
                            <span className="form-label">E-mail</span>
                          </div>
                          <Input
                            name="email"
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Digite seu e-mail"
                          />
                        </div>
                        <div className="form-group">
                          <div className="form-label-group">
                            <span className="form-label">Senha</span>
                          </div>
                          <div className="form-control-wrap">
                            <Anchor
                              tabIndex={-1}
                              className="form-icon form-icon-right passcode-switch"
                              data-target="password"
                              onClick={e => e.preventDefault()}
                            >
                              <em
                                className={`passcode-icon icon-show icon ni
                                  ${verSenha ? 'ni-eye-off' : 'ni-eye'}`}
                                onClick={() => setVerSenha(!verSenha)}
                              />
                            </Anchor>
                            <Input
                              name="password"
                              type={verSenha ? 'text' : 'password'}
                              className="form-control form-control-lg"
                              placeholder="Digite sua senha"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <Button
                            type="submit"
                            className="btn btn-lg btn-primary btn-block"
                          >
                            Entrar
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="fadeInRight fadeOutLeft animated nk-block-content nk-error-ld text-center">
                  <h5
                    className="nk-error-title"
                    style={{ marginBottom: 0, paddingBottom: 0, fontSize: 20 }}
                  >
                    Para utilizar a integração com o whatsapp entre em contato.
                    <span className="nk-error-head" style={{ fontSize: 20 }}>
                      {' '}
                      (11) 4479-6605
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
