/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { io } from 'socket.io-client';
import get from 'lodash.get';
import { useAutenticacao } from './Usuario';
import api from '../utils/api';
import { notifyError, notifyWarning } from '../utils/toasts';
import { url } from '../services/xhrApi';

interface WhatsAppSessao {
  whatsapp: StatusQrcode;
  usuarioSessao: any;
  temSessao: boolean;
  loginSessao(): void;
  sairSessao(): void;
}

interface StatusQrcode {
  qrcode: string | null;
  statusSessao: string;
  mensagemLoad: string;
}

const WhatsLogado = createContext<WhatsAppSessao>({} as WhatsAppSessao);
export const WhatsProvider = ({ children }: any): ReactElement => {
  const { usuario, tokenIntegracao } = useAutenticacao();
  const [usuarioSessao, setUsuarioSessao] = useState(() => {
    const jsonSessao = localStorage.getItem('@Whatsapi:usuarioSessao');
    if (jsonSessao) {
      return JSON.parse(jsonSessao);
    }
    return null;
  });
  const [temSessao, setTemSessao] = useState(() => {
    const jsonSessao = localStorage.getItem('@Whatsapi:usuarioSessao');
    if (jsonSessao) {
      return true;
    }
    return false;
  });
  const [whatsapp, setWhatsapp] = useState<StatusQrcode>({
    qrcode: '',
    statusSessao: 'loading',
    mensagemLoad: '',
  });

  const changeSessaoWhatsapp = (
    mensagem: string,
    status: string,
    qrcode?: string | null,
  ) => {
    setWhatsapp({
      qrcode: qrcode || null,
      mensagemLoad: mensagem,
      statusSessao: status,
    });
  };

  const sairSessao = () => {
    changeSessaoWhatsapp('Finalizando sessão...', 'loading');
    setTimeout(async () => {
      try {
        await api.sairSessao(tokenIntegracao);
        localStorage.removeItem('@Whatsapi:usuarioSessao');
        setTemSessao(false);
        changeSessaoWhatsapp('', 'offline');
      } catch (error) {
        console.log('Erro ao encerrar sessao!');
        notifyError('Algo deu errado ao encerrar a sessão');
        changeSessaoWhatsapp('', 'online');
      }
    }, 1000);
  };

  const setUsuarioWhatsapp = async () => {
    try {
      setTimeout(async () => {
        const { data } = await api.getUsuarioSessao(tokenIntegracao);

        if (data) {
          localStorage.setItem(
            '@Whatsapi:usuarioSessao',
            JSON.stringify(data.usuario),
          );
          setUsuarioSessao(data.usuario);
          setTemSessao(true);
          changeSessaoWhatsapp('', 'online');
        }
      }, 3000);
    } catch (error) {
      changeSessaoWhatsapp('', 'offline');
    }
  };

  const verificarUsuarioWhatsapp = async () => {
    changeSessaoWhatsapp('Buscando sessão...', 'loading');
    const json = localStorage.getItem('@Whatsapi:usuarioSessao');

    if (json) {
      setUsuarioSessao(JSON.parse(json));
      setTemSessao(true);
      changeSessaoWhatsapp('', 'online');
      return;
    }

    await setUsuarioWhatsapp();
  };

  const procurarSessao = async () => {
    try {
      const { data } = await api.getIsConnected(tokenIntegracao);

      if (!data.isConnected) {
        changeSessaoWhatsapp('Criando sessão...', 'loading');
        await api.iniciarSession(tokenIntegracao);
        return;
      }

      const { data: session } = await api.getSessionAtiva(tokenIntegracao);

      if (
        ['isLogged', 'qrReadSuccess', 'chatsAvailable'].includes(session.state)
      ) {
        await verificarUsuarioWhatsapp();
        return;
      }

      acompanharSessao(session.state);
      localStorage.removeItem('@Whatsapi:usuarioSessao');
    } catch (error) {
      console.log(get(error, 'response.data.message', 'Erro ao buscar sessão'));
      changeSessaoWhatsapp('', 'offline');
    }
  };

  const loginSessao = async () => {
    try {
      setTemSessao(false);
      setUsuarioSessao(null);
      changeSessaoWhatsapp('Criando sessão...', 'loading');

      const { data } = await api.iniciarSession(tokenIntegracao);
      acompanharSessao(data.status);
    } catch (error) {
      changeSessaoWhatsapp('', 'offline');
    }
  };

  const acompanharSessao = async (status: string) => {
    console.log('acompanharSessao -> ', status);
    if (['scanQrcode'].includes(status)) {
      const { data } = await api.getQrcode(tokenIntegracao);
      changeSessaoWhatsapp('', 'scanQrcode', data.qrcode);
      return;
    }

    if (['isLogged', 'qrReadSuccess', 'chatsAvailable'].includes(status)) {
      await verificarUsuarioWhatsapp();
      return;
    }

    if (
      [
        'notLogged',
        'browserClose',
        'desconnectedMobile',
        'autocloseCalled',
        'qrReadFail',
      ].includes(status)
    ) {
      const json = localStorage.getItem('@Whatsapi:usuarioSessao');
      if (json) {
        localStorage.removeItem('@Whatsapi:usuarioSessao');
      }
      setUsuarioSessao(null);
      setTemSessao(false);
      changeSessaoWhatsapp('', 'offline');
      return;
    }

    if (['creating'].includes(status)) {
      changeSessaoWhatsapp('Criando sessão...', 'loading');
      return;
    }

    if (['serverWssNotConnected', 'noOpenBrowser'].includes(status)) {
      notifyWarning('Algo deu errado, por favor tente mais tarde!');
    }
  };

  useEffect(() => {
    try {
      if (!usuario || !usuario.ativo) {
        return;
      }

      const socket = io(url, {
        query: { session: usuario.email },
      });

      socket.on('statusWhatsapp', status => {
        acompanharSessao(status);
      });

      procurarSessao();
    } catch (error) {
      changeSessaoWhatsapp('', 'offline');
    }
  }, [usuario]);

  return (
    <WhatsLogado.Provider
      value={{
        whatsapp,
        usuarioSessao,
        temSessao,
        loginSessao,
        sairSessao,
      }}
    >
      {children}
    </WhatsLogado.Provider>
  );
};

export const useAutenticacaoWhats = (): WhatsAppSessao => {
  const context = useContext(WhatsLogado);

  if (!context) {
    throw new Error('useAutenticacao não está no seu Provider');
  }

  return context;
};
