import { ReactElement } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { ToastContainer, Zoom } from 'react-toastify';
import GlobalStyle from './styles/global';

import { UsuarioProvider } from './context/Usuario';
import { WhatsProvider } from './context/WhatsApp';

import Routes from './routes';

const App = (): ReactElement => (
  <>
    <UsuarioProvider>
      <WhatsProvider>
        <Router>
          <ToastContainer limit={1} transition={Zoom} />
          <Routes />
          <GlobalStyle />
        </Router>
      </WhatsProvider>
    </UsuarioProvider>
  </>
);

export default App;
