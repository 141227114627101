import { ReactElement } from 'react';
import Anchor from '../../components/Anchor';
import { useAutenticacao } from '../../context/Usuario';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const Profile = (): ReactElement => {
  const { usuario } = useAutenticacao();

  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <Sidebar />
        <div className="nk-wrap">
          <Header />
          <div className="nk-content">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block">
                    <div className="card card-bordered">
                      <div className="card-aside-wrap">
                        <div className="card-inner card-inner-lg">
                          <div className="nk-block-head nk-block-head-lg">
                            <div className="nk-block-between">
                              <div className="nk-block-head-content">
                                <h4 className="nk-block-title">
                                  Informações pessoais
                                </h4>
                                <div className="nk-block-des">
                                  <p>Informações básicas do seu perfil.</p>
                                </div>
                              </div>
                              <div className="nk-block-head-content align-self-start d-lg-none">
                                <Anchor
                                  className="toggle btn btn-icon btn-trigger mt-n1"
                                  data-target="userAside"
                                >
                                  <em className="icon ni ni-menu-alt-r" />
                                </Anchor>
                              </div>
                            </div>
                          </div>
                          <div className="nk-block">
                            <div className="nk-data data-list">
                              <div className="data-head">
                                <h6 className="overline-title">Meu perfil</h6>
                              </div>
                              <div className="data-item">
                                <div className="data-col">
                                  <span className="data-label">Nome</span>
                                  <span className="data-value">
                                    {usuario.name}
                                  </span>
                                </div>
                                <div className="data-col data-col-end">
                                  <span className="data-more disable">
                                    <em className="icon ni ni-user" />
                                  </span>
                                </div>
                              </div>
                              <div className="data-item">
                                <div className="data-col">
                                  <span className="data-label">E-mail</span>
                                  <span className="data-value">
                                    {usuario.email}
                                  </span>
                                </div>
                                <div className="data-col data-col-end">
                                  <span className="data-more disable">
                                    <em className="icon ni ni-lock-alt" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Profile;
