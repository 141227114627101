import { ReactElement, useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import get from 'lodash.get';
import * as Yup from 'yup';
import api from '../../utils/api';
import getValidarErros from '../../utils/getValidarErros';
import { notifyError, notifySuccess, notifyWarning } from '../../utils/toasts';
import Button from '../../components/Button';
import Anchor from '../../components/Anchor';
import Input from '../../components/Input';
import { useAutenticacao } from '../../context/Usuario';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const AlterarSenha = (): ReactElement => {
  const { usuario } = useAutenticacao();
  const formRef = useRef<FormHandles>(null);
  const [verSenhaAtual, setVerSenhaAtual] = useState(false);
  const [verNovaSenha, setVerNovaSenha] = useState(false);
  const [verConfirmarSenha, setVerConfirmarSenha] = useState(false);

  const isValid = (novaSenha: string, confirmarSenha: string) => {
    if (novaSenha.includes(' ')) {
      notifyWarning('Não deve conter espaços');
      return false;
    }

    if (novaSenha !== confirmarSenha) {
      notifyWarning('As senhas não correspondem');
      return false;
    }

    return true;
  };

  const alterarSenha = async () => {
    const senhaAtual = (document.getElementById(
      'senhaAtual',
    ) as HTMLInputElement).value;
    const novaSenha = (document.getElementById('novaSenha') as HTMLInputElement)
      .value;
    const confirmarSenha = (document.getElementById(
      'confirmarSenha',
    ) as HTMLInputElement).value;

    const senhaValida = isValid(novaSenha, confirmarSenha);

    if (!senhaValida) {
      return;
    }

    try {
      await api.alterarSenha(usuario.email, novaSenha, senhaAtual);
      notifySuccess('Senha alterada com sucesso!');
    } catch (error) {
      notifyError(
        get(
          error,
          'response.data.message',
          'Algo deu errado ao alterar a senha!',
        ),
      );
    }
  };

  const handleTrocarSenha = useCallback(async (data: string) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        senhaAtual: Yup.string().min(6, 'No mínimo 6 digitos'),
        novaSenha: Yup.string().min(6, 'No mínimo 6 digitos'),
        confirmarSenha: Yup.string().min(6, 'No mínimo 6 digitos'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      alterarSenha();
    } catch (error) {
      const erros = getValidarErros(error);
      formRef.current?.setErrors(erros);
      notifyWarning('As senhas deem conter no mínimo 6 caracteres!');
    }
  }, []);

  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <Sidebar />
        <div className="nk-wrap">
          <Header />
          <div className="nk-content">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block">
                    <div className="card card-bordered">
                      <div className="card-aside-wrap">
                        <div className="card-inner card-inner-lg">
                          <div className="nk-block-head nk-block-head-lg">
                            <div className="nk-block-between">
                              <div className="nk-block-head-content">
                                <h4 className="nk-block-title">
                                  Alterar senha
                                </h4>
                                <div className="nk-block-des">
                                  <p>Altere a senha da sua conta.</p>
                                </div>
                              </div>
                              <div className="nk-block-head-content align-self-start d-lg-none">
                                <Anchor
                                  className="toggle btn btn-icon btn-trigger mt-n1"
                                  data-target="userAside"
                                >
                                  <em className="icon ni ni-menu-alt-r" />
                                </Anchor>
                              </div>
                            </div>
                          </div>
                          <div className="nk-block">
                            <div className="nk-data data-list">
                              <div className="data-head">
                                <h6 className="overline-title">Minha senha</h6>
                              </div>
                              <div
                                className="tab-pane fadeInRight animated"
                                id="alterar-senha-usuario"
                                aria-expanded="false"
                              >
                                <div className="m-portlet m-portlet--tabs">
                                  <Form
                                    ref={formRef}
                                    onSubmit={handleTrocarSenha}
                                    className="formSenha"
                                  >
                                    <div
                                      style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <div className="form-group m-form__group row justify-center mb-4">
                                        <span
                                          className="col-form-label col-lg-3 col-sm-12"
                                          html-for="senha-antiga-usuario"
                                          style={{ width: 350 }}
                                        >
                                          Senha atual:&nbsp;
                                          <span className="badge-outline-danger">
                                            *
                                          </span>
                                        </span>
                                        <div className="form-control-wrap">
                                          <Anchor
                                            tabIndex={-1}
                                            className="form-icon form-icon-right passcode-switch"
                                            data-target="password"
                                            onClick={e => e.preventDefault()}
                                          >
                                            <em
                                              className={`passcode-icon icon-show icon ni
                                              ${
                                                verSenhaAtual
                                                  ? 'ni-eye-off'
                                                  : 'ni-eye'
                                              }`}
                                              onClick={() =>
                                                setVerSenhaAtual(!verSenhaAtual)
                                              }
                                            />
                                          </Anchor>
                                          <Input
                                            type={
                                              verSenhaAtual
                                                ? 'text'
                                                : 'password'
                                            }
                                            style={{ width: 310 }}
                                            name="senhaAtual"
                                            id="senhaAtual"
                                            className="form-control m-input"
                                          />
                                        </div>
                                      </div>
                                      <div className="form-group m-form__group row justify-center">
                                        <span
                                          className="col-form-label col-lg-3 col-sm-12"
                                          html-for="senha-antiga-usuario"
                                          style={{ width: 350 }}
                                        >
                                          Nova senha:&nbsp;
                                          <span className="badge-outline-danger">
                                            *
                                          </span>
                                        </span>
                                        <div>
                                          <div className="form-control-wrap">
                                            <Anchor
                                              tabIndex={-1}
                                              className="form-icon form-icon-right passcode-switch"
                                              data-target="password"
                                              onClick={e => e.preventDefault()}
                                            >
                                              <em
                                                className={`passcode-icon icon-show icon ni
                                                ${
                                                  verNovaSenha
                                                    ? 'ni-eye-off'
                                                    : 'ni-eye'
                                                }`}
                                                onClick={() =>
                                                  setVerNovaSenha(!verNovaSenha)
                                                }
                                              />
                                            </Anchor>
                                            <Input
                                              type={
                                                verNovaSenha
                                                  ? 'text'
                                                  : 'password'
                                              }
                                              style={{ width: 310 }}
                                              name="novaSenha"
                                              id="novaSenha"
                                              className="form-control m-input"
                                            />
                                          </div>
                                          <p
                                            className="help-block"
                                            style={{
                                              fontSize: 13,
                                              maxWidth: 300,
                                            }}
                                          >
                                            *Deve conter no mínimo 6 caracteres
                                            e não deve conter espaços!
                                          </p>
                                        </div>
                                      </div>
                                      <div className="form-group m-form__group row justify-center mb-4">
                                        <span
                                          className="col-form-label col-lg-3 col-sm-12"
                                          html-for="senha-antiga-usuario"
                                          style={{ width: 350 }}
                                        >
                                          Confirmar senha:&nbsp;
                                          <span className="badge-outline-danger">
                                            *
                                          </span>
                                        </span>
                                        <div>
                                          <div className="form-control-wrap">
                                            <Anchor
                                              tabIndex={-1}
                                              className="form-icon form-icon-right passcode-switch"
                                              data-target="password"
                                              onClick={e => e.preventDefault()}
                                            >
                                              <em
                                                className={`passcode-icon icon-show icon ni
                                                ${
                                                  verConfirmarSenha
                                                    ? 'ni-eye-off'
                                                    : 'ni-eye'
                                                }`}
                                                onClick={() =>
                                                  setVerConfirmarSenha(
                                                    !verConfirmarSenha,
                                                  )
                                                }
                                              />
                                            </Anchor>
                                            <Input
                                              type={
                                                verConfirmarSenha
                                                  ? 'text'
                                                  : 'password'
                                              }
                                              style={{ width: 310 }}
                                              id="confirmarSenha"
                                              name="confirmarSenha"
                                              className="form-control m-input"
                                            />
                                          </div>
                                          <p
                                            className="help-block"
                                            style={{ fontSize: 13 }}
                                          >
                                            Favor, redigite a nova senha!
                                          </p>
                                        </div>
                                      </div>
                                      <div className="m-portlet__foot m-portlet__foot--fit">
                                        <div className="m-form__actions m-form__actions">
                                          <div className="row">
                                            <div className="col-lg-9 ml-lg-auto justify-center">
                                              <Button
                                                type="submit"
                                                className="btn btn-primary"
                                                style={{
                                                  width: 200,
                                                  justifyContent: 'center',
                                                }}
                                              >
                                                Alterar
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AlterarSenha;
