import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Login from '../pages/Login';
import Qrcode from '../pages/Qrcode';
import EntrarEmContato from '../pages/EntrarEmContato';
import Perfil from '../pages/Profile';
import error404 from '../pages/Errors/404';
import AlterarSenha from '../pages/AlterarSenha';
import Integracao from '../pages/Integracao';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/error/404" exact component={error404} />
      <Route
        path="/entrar-contato"
        isPrivate
        exact
        component={EntrarEmContato}
      />
      <Route path="/qrcode" exact component={Qrcode} isPrivate />
      <Route path="/perfil" exact component={Perfil} isPrivate />
      <Route path="/alterar-senha" exact component={AlterarSenha} isPrivate />
      <Route path="/integracao" exact component={Integracao} isPrivate />
    </Switch>
  );
};

export default Routes;
