import { ReactElement } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SettingsIcon from '@material-ui/icons/Settings';
import Lottie, { Options } from 'react-lottie';
import loadingQr from '../../assets/lottie/loading-qrcode.json';
import { useAutenticacaoWhats } from '../../context/WhatsApp';
import loadingQrcode from '../../assets/imagens/loading-qrcode.png';
import maskTelefone from '../../utils/mask-telefone';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Sidebar from '../../components/Sidebar';

const Qrcode = (): ReactElement => {
  const {
    whatsapp,
    usuarioSessao,
    loginSessao,
    sairSessao,
  } = useAutenticacaoWhats();

  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: loadingQr,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="nk-app-root">
      <div className="nk-main ">
        <Sidebar />
        <div className="nk-wrap ">
          <Header />
          <div className="criarSessao-backGround">
            <div className="criarSessao-container">
              <ol className="criarSessao-listaPassoAPasso text-body">
                <div className="criarSessao-title">
                  <text>Para conectar seu Whatsapp</text>
                </div>
                <li>1. Abra seu WhatsApp em seu celular</li>
                <li>
                  2. Toque em
                  <span>
                    &nbsp;Menu
                    <MoreVertIcon style={{ fontWeight: 'bold', width: 20 }} />
                  </span>
                  ou
                  <span> Configurações </span>
                  <SettingsIcon style={{ fontWeight: 'bold', width: 20 }} />
                  &nbsp;e selecione&nbsp;
                  <span>Aparelhos conectados</span>
                </li>
                <li>3. Clique em conectar um aparelho</li>
                <li>
                  4. Aponte o seu celular para essa tela para capturar o código
                </li>
              </ol>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: 320,
                  alignItems: 'center',
                  paddingLeft: 200,
                }}
              >
                <img
                  style={{ cursor: 'pointer' }}
                  src={loadingQrcode}
                  alt="qrcode"
                  onClick={loginSessao}
                  className={`criarSessao-Qrcode
                    ${
                      whatsapp.statusSessao === 'offline'
                        ? 'showComponent'
                        : 'hiddenComponent'
                    }`}
                />
                <div
                  style={{ alignItems: 'center' }}
                  className={`
                    ${
                      whatsapp.statusSessao === 'loading' && !whatsapp.qrcode
                        ? 'showComponent'
                        : 'hiddenComponent'
                    }`}
                >
                  <Lottie options={defaultOptions} height={220} width={220} />
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: 20,
                      color: '#6f6f6f',
                    }}
                  >
                    {whatsapp.mensagemLoad}
                  </div>
                </div>

                <img
                  src={whatsapp.qrcode ? whatsapp.qrcode : ''}
                  alt="qrcode"
                  className={`criarSessao-Qrcode
                    ${
                      whatsapp.statusSessao === 'scanQrcode' || whatsapp.qrcode
                        ? 'showComponent'
                        : 'hiddenComponent'
                    }`}
                />

                <div
                  className={`
                    ${
                      whatsapp.statusSessao === 'online'
                        ? 'showComponent'
                        : 'hiddenComponent'
                    }`}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {whatsapp.statusSessao === 'online' && usuarioSessao && (
                      <>
                        <div className="user-card user-card-s2 my-4">
                          <div
                            className="user-avatar md bg-purple criarSessao-avatar"
                            style={{ width: 150, height: 150 }}
                          >
                            {usuarioSessao ? (
                              <img
                                src={usuarioSessao.imgUrl}
                                alt="Imagem de perfil"
                                style={{ width: 400 }}
                              />
                            ) : (
                              <span style={{ fontSize: 55 }}>
                                {usuarioSessao.displayName[0]}
                              </span>
                            )}
                          </div>
                          <div className="user-info">
                            <h5 style={{ fontSize: 22 }}>
                              {maskTelefone(usuarioSessao.id.user)}
                            </h5>
                            <span className="sub-text" style={{ fontSize: 18 }}>
                              {usuarioSessao.displayName}
                            </span>
                          </div>
                        </div>

                        <button
                          type="button"
                          onClick={sairSessao}
                          className="btn btn-lg btn-primary btn-danger"
                        >
                          Desligar sessão
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Qrcode;
