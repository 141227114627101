/* eslint-disable consistent-return */
import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import get from 'lodash.get';
import api from '../utils/api';
import { notifyError, notifyWarning } from '../utils/toasts';

interface Credenciais {
  email: string;
  password: string;
}

interface UsuarioSessao {
  usuario: any;
  tokenIntegracao: any;
  login(credenciais: Credenciais): Promise<void>;
  sair(): void;
}

interface Sessao {
  token?: string;
  tokenIntegracao?: string;
  user?: any;
}

interface Props {
  children: ReactNode;
}

const UsuarioLogado = createContext<UsuarioSessao>({} as UsuarioSessao);

export const UsuarioProvider = ({ children }: Props): ReactElement => {
  const [data, setData] = useState<Sessao>(() => {
    const token = localStorage.getItem('@whatsapi:token');
    const user = localStorage.getItem('@whatsapi:user');

    if (token && user) {
      return { token, ...JSON.parse(user) };
    }

    return {} as Sessao;
  });

  const login = useCallback(async ({ email, password }) => {
    try {
      const response = await api.login(email, password);
      const { token, user } = response.data;

      if (!Number(user.ativo)) {
        notifyWarning('Seu usuário não está ativo!');
        return;
      }

      localStorage.setItem('@whatsapi:token', token);

      const {
        data: { tokenIntegracao },
      }: any = await api.getTokenIntegracao(user.email, token);

      localStorage.setItem(
        '@whatsapi:user',
        JSON.stringify({
          user,
          tokenIntegracao,
        }),
      );

      setData({ token, user, tokenIntegracao });
    } catch (error) {
      notifyError(
        get(
          error,
          'response.data.message',
          'Algo deu errado, tente novamente!',
        ),
      );
    }
  }, []);

  const sair = useCallback(() => {
    localStorage.removeItem('@whatsapi:token');
    localStorage.removeItem('@whatsapi:user');
    localStorage.removeItem('@Whatsapi:usuarioSessao');

    setData({} as Sessao);
  }, []);

  return (
    <UsuarioLogado.Provider
      value={{
        usuario: data.user,
        tokenIntegracao: data.tokenIntegracao,
        login,
        sair,
      }}
    >
      {children}
    </UsuarioLogado.Provider>
  );
};

export function useAutenticacao(): UsuarioSessao {
  const context = useContext(UsuarioLogado);

  if (!context) {
    throw new Error('useAutenticacao não está no seu Provider');
  }

  return context;
}
