import React from 'react';
import { Link } from 'react-router-dom';
import Anchor from '../Anchor';
import Logo from '../../assets/imagens/whatsapi-logo.png';
import { useAutenticacao } from '../../context/Usuario';

const Header: React.FC = () => {
  const { usuario, sair } = useAutenticacao();

  return (
    <div className="nk-header nk-header-fixed is-light">
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <Anchor
              className="nk-nav-toggle nk-quick-nav-icon"
              data-target="sidebarMenu"
            >
              <em className="icon ni ni-menu" />
            </Anchor>
          </div>
          <div className="nk-header-brand d-xl-none">
            <a href="html/index.html" className="logo-link">
              <img
                className="logo-light logo-img"
                src={Logo}
                srcSet="./images/logo2x.png 2x"
                alt="logo"
              />
              <img
                className="logo-dark logo-img"
                src={Logo}
                srcSet="./images/logo-dark2x.png 2x"
                alt="logo-dark"
              />
            </a>
          </div>
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li className="dropdown user-dropdown">
                <Anchor className="dropdown-toggle" data-toggle="dropdown">
                  <div className="user-toggle">
                    <div className="user-avatar sm">
                      <em className="icon ni ni-user-alt" />
                    </div>
                    <div className="user-info d-none d-md-block">
                      <div className="user-status">Administrator</div>
                      <div className="user-name dropdown-indicator">
                        {usuario.name.split(' ').shift()}
                      </div>
                    </div>
                  </div>
                </Anchor>
                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1">
                  <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                    <div className="user-card">
                      <div className="user-avatar">
                        <span>{usuario.name[0]}</span>
                      </div>
                      <div className="user-info">
                        <span className="lead-text">{usuario.name}</span>
                        <span className="sub-text">{usuario.email}</span>
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li>
                        <Link to="/perfil">
                          <em className="icon ni ni-user" />
                          <span>Dados da conta</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li>
                        <Anchor onClick={sair}>
                          <em className="icon ni ni-signout" />
                          <span>Sair</span>
                        </Anchor>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
