import { ReactElement } from 'react';

const Footer = (): ReactElement => (
  <div className="nk-footer">
    <div className="container-fluid">
      <div className="nk-footer-wrap">
        <div className="nk-footer-copyright">
          &copy; 2021
          <a href="https://maxscalla.com.br" target="_blank" rel="noreferrer">
            &nbsp;Max Scalla
          </a>
          . Todos os direitos reservados.
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
