import { AxiosPromise, AxiosRequestConfig } from 'axios';
import xhrApi from '../services/xhrApi';

interface DataQrcode {
  result: string;
  message: string;
  qrcode: string;
}

const setDynamicHeader = (token: string): AxiosRequestConfig => ({
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
});

const alterarSenha = (
  email: string,
  novaSenha: string,
  senhaAtual: string,
): AxiosPromise =>
  xhrApi.put('/usuario/alterar-senha', { email, novaSenha, senhaAtual });

const login = (email: string, password: string): AxiosPromise =>
  xhrApi.post('/login', { email, password });

const getUsuario = (email: string): AxiosPromise =>
  xhrApi.get(`/usuario/${email}?`);

const getTokenIntegracao = (email: string, token: string): AxiosPromise =>
  xhrApi.get(`/integracoes/token/${email}`, setDynamicHeader(token));

// whatsapp
const getQrcode = (tokenIntegracao: string): AxiosPromise<DataQrcode> =>
  xhrApi.get(`/whatsapp/qrcode?token=${tokenIntegracao}`);

const getUsuarioSessao = (tokenIntegracao: string): AxiosPromise =>
  xhrApi.get(`/whatsapp/usuario?token=${tokenIntegracao}`);

const getConversasPorId = (
  wid: string,
  tokenIntegracao: string,
): AxiosPromise =>
  xhrApi.get(`/whatsapp/conversas/${wid}@c.us?token=${tokenIntegracao}`);

const getConversas = (tokenIntegracao: string): AxiosPromise =>
  xhrApi.get(`/whatsapp/conversas?token=${tokenIntegracao}`);

const iniciarSession = (tokenIntegracao: string): AxiosPromise =>
  xhrApi.post(`/whatsapp/session?token=${tokenIntegracao}`);

const getSessionAtiva = (tokenIntegracao: string): AxiosPromise =>
  xhrApi.get(`/whatsapp/session?token=${tokenIntegracao}`);

const sairSessao = (tokenIntegracao: string): AxiosPromise =>
  xhrApi.post(`/whatsapp/sair?token=${tokenIntegracao}`);

const checkNumber = (
  phoneNumber: string,
  tokenIntegracao: string,
): AxiosPromise =>
  xhrApi.get(`/whatsapp/check-number/${phoneNumber}?token=${tokenIntegracao}`);

const enviarMensagem = (
  mensagem: string,
  telefone: string,
  tokenIntegracao: string,
): AxiosPromise =>
  xhrApi.post(`whatsapp/send?token=${tokenIntegracao}`, { mensagem, telefone });

const getIsConnected = (tokenIntegracao: string): AxiosPromise =>
  xhrApi.get(`/whatsapp/isConnected?token=${tokenIntegracao}`);

export default {
  login,
  getUsuario,
  getQrcode,
  iniciarSession,
  getSessionAtiva,
  getUsuarioSessao,
  getConversas,
  getConversasPorId,
  sairSessao,
  checkNumber,
  enviarMensagem,
  alterarSenha,
  getTokenIntegracao,
  getIsConnected,
};
