import { ReactElement, useState } from 'react';
import Logo from '../../assets/imagens/whatsapi-logo.png';
import { useAutenticacao } from '../../context/Usuario';
import Anchor from '../Anchor';

const Sidebar = (): ReactElement => {
  const { sair } = useAutenticacao();
  const pagina = window.location.pathname;
  const [hideSideBar, setHideSideBar] = useState<boolean>(false);

  return (
    <div
      className={`nk-sidebar nk-sidebar-fixed is-dark ${
        hideSideBar && 'is-compact'
      }`}
    >
      <div className="nk-sidebar-element nk-sidebar-head">
        <div className="nk-menu-trigger">
          <Anchor
            className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
            onClick={e => {
              setHideSideBar(!hideSideBar);
              e.preventDefault();
            }}
          >
            <em className="icon ni ni-arrow-left" />
          </Anchor>
          <Anchor
            onClick={e => {
              setHideSideBar(!hideSideBar);
              e.preventDefault();
            }}
            className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex"
            data-target="sidebarMenu"
          >
            <em className="icon ni ni-menu" />
          </Anchor>
        </div>
        <div className="nk-sidebar-brand">
          <Anchor
            onClick={e => e.preventDefault()}
            className="logo-link nk-sidebar-logo"
          >
            <img
              className="logo-light logo-img"
              src={Logo}
              srcSet="./images/logo2x.png 2x"
              alt="logo"
            />
            <img
              className="logo-dark logo-img"
              src={Logo}
              srcSet="./images/logo-dark2x.png 2x"
              alt="logo-dark"
            />
          </Anchor>
        </div>
      </div>
      <div className="nk-sidebar-element nk-sidebar-body">
        <div className="nk-sidebar-content">
          <div className="nk-sidebar-menu" data-simplebar>
            <ul className="nk-menu">
              <li
                className={`${
                  pagina === '/qrcode' ? 'active' : ''
                } nk-menu-item`}
              >
                <Anchor href="/qrcode" className="nk-menu-link">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-home" />
                  </span>
                  <span className="nk-menu-text p-0">Home</span>
                </Anchor>
              </li>
              <li
                className={`${
                  pagina === '/perfil' ? 'active' : ''
                } nk-menu-item`}
              >
                <Anchor href="/perfil" className="nk-menu-link">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-account-setting" />
                  </span>
                  <span className="nk-menu-text p-0">Dados da conta</span>
                </Anchor>
              </li>
              <li
                className={`${
                  pagina === '/integracao' ? 'active' : ''
                } nk-menu-item`}
              >
                <Anchor href="/integracao" className="nk-menu-link">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-share-alt" />
                  </span>
                  <span className="nk-menu-text p-0">Integração</span>
                </Anchor>
              </li>
              <li
                className={`${
                  pagina === '/alterar-senha' ? 'active' : ''
                } nk-menu-item`}
              >
                <Anchor href="/alterar-senha" className="nk-menu-link">
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-lock-alt" />
                  </span>
                  <span className="nk-menu-text p-0">Alterar senha</span>
                </Anchor>
              </li>
              <li className="nk-menu-item">
                <Anchor
                  className="nk-menu-link"
                  onClick={e => {
                    e.preventDefault();
                    sair();
                  }}
                >
                  <span className="nk-menu-icon">
                    <em className="icon ni ni-signout" />
                  </span>
                  <span className="nk-menu-text p-0">Sair</span>
                </Anchor>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
