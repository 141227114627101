import { ReactElement, useState } from 'react';
import copy from 'copy-to-clipboard';
import Anchor from '../../components/Anchor';
import { useAutenticacao } from '../../context/Usuario';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const Integracao = (): ReactElement => {
  const { tokenIntegracao } = useAutenticacao();
  const [copiado, setCopiado] = useState(false);

  const copiar = (e: any) => {
    e.preventDefault();
    setCopiado(true);
    copy(tokenIntegracao);
    setTimeout(() => {
      setCopiado(false);
    }, 3000);
  };

  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <Sidebar />
        <div className="nk-wrap">
          <Header />
          <div className="nk-content">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block">
                    <div className="card card-bordered">
                      <div className="card-aside-wrap">
                        <div className="card-inner card-inner-lg">
                          <div className="nk-block-head nk-block-head-lg">
                            <div className="nk-block-between">
                              <div className="nk-block-head-content">
                                <h4 className="nk-block-title">Integração</h4>
                                <div className="nk-block-des">
                                  <p>
                                    Utilize esse token para realizar a
                                    integração
                                  </p>
                                </div>
                              </div>
                              <div className="nk-block-head-content align-self-start d-lg-none">
                                <Anchor
                                  className="toggle btn btn-icon btn-trigger mt-n1"
                                  data-target="userAside"
                                >
                                  <em className="icon ni ni-menu-alt-r" />
                                </Anchor>
                              </div>
                            </div>
                          </div>
                          <div className="nk-block">
                            <div className="nk-data data-list">
                              <div className="data-head">
                                <h6 className="overline-title">
                                  Token de integração
                                </h6>
                              </div>
                              <div className="data-item">
                                <div className="data-col">
                                  <span className="data-label">Token</span>
                                  <span className="data-value">
                                    {tokenIntegracao}
                                  </span>
                                </div>
                                {copiado ? (
                                  <div className="data-col data-col-end">
                                    <span
                                      className="text-primary"
                                      style={{ marginTop: 4 }}
                                    >
                                      copiado
                                    </span>
                                    <span className="data-more disable">
                                      <em className="icon ni ni-copy-fill text-primary" />
                                    </span>
                                  </div>
                                ) : (
                                  <Anchor
                                    className="data-col data-col-end"
                                    onClick={e => copiar(e)}
                                  >
                                    <span
                                      className="text-body"
                                      style={{ marginTop: 4 }}
                                    >
                                      copiar
                                    </span>
                                    <span className="data-more disable">
                                      <em className="icon ni ni-copy-fill" />
                                    </span>
                                  </Anchor>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Integracao;
