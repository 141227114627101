import { ReactElement } from 'react';

const error404 = (): ReactElement => (
  <div className="nk-app-root">
    <div className="nk-main">
      <div className="nk-wrap nk-wrap-nosidebar">
        <div className="nk-content">
          <div className="nk-block nk-block-middle wide-xs mx-auto">
            <div className="nk-block-content nk-error-ld text-center">
              <h1 className="nk-error-head">404</h1>
              <h3 className="nk-error-title">Oops! Por que você está aqui?</h3>
              <p className="nk-error-text">
                Sentimos muito pelo inconveniente. Parece que você está tentando
                acessar uma página que foi excluída ou nunca existiu.
              </p>
              <a href="/" className="btn btn-lg btn-primary mt-2">
                Voltar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default error404;
