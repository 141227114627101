import { ReactElement } from 'react';
import logo from '../../assets/imagens/whatsapi-logo.png';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const EntrarEmContato = (): ReactElement => {
  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <div className="nk-wrap nk-wrap-nosidebar">
          <div className="nk-content">
            <div style={{ flex: 1 }} className="align-center justify-center">
              <Header />
              <div className="fadeInRight fadeOutLeft animated nk-block-content nk-error-ld text-center">
                <h1 className="nk-error-head">
                  <img src={logo} alt="logo" style={{ width: 400 }} />
                </h1>
                <h3 className="nk-error-title">
                  Você deseja fazer a integração com o whatsapp?
                </h3>
                <p className="nk-error-text">
                  Caso você queira fazer a integração ao whatsapp através dos
                  nossos serviços, por favor entre em contato com o número
                  abaixo
                </p>
                <div
                  className="d-flex align-center"
                  style={{ flexDirection: 'column' }}
                >
                  <span className="nk-error-head" style={{ fontSize: 30 }}>
                    (11) 4479-6605
                  </span>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntrarEmContato;
